import React from "react"
import Helmet from "react-helmet"
import { graphql } from 'gatsby'
import Layout from "../components/layout"

const ResumePage = ({
  data: {
    site
  },
}) => {
  return (
    <Layout>
      <Helmet>
        <title>Resume — {site.siteMetadata.title}</title>
        <meta name="description" content={"Resume page of " + site.siteMetadata.description} />
      </Helmet>
        <header>
          <h2 className="h2 article-title">Resume</h2>
        </header>
        <section className="timeline">
          <div className="title-wrapper">
            <div className="icon-box">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                <path className="fa-primary" d="M320 32c-8.1 0-16.1 1.4-23.7 4.1L15.8 137.4C6.3 140.9 0 149.9 0 160s6.3 19.1 15.8 22.6l77.4 27.9c16.8-17.8 37.8-31.9 61.7-40.9l159.4-59.8c8.3-3.1 17.5 1.1 20.6 9.4s-1.1 17.5-9.4 20.6L166.2 199.6c-14.6 5.5-27.8 13.4-39.3 23.1l169.5 61.2c7.6 2.7 15.6 4.1 23.7 4.1s16.1-1.4 23.7-4.1L624.2 182.6c9.5-3.4 15.8-12.5 15.8-22.6s-6.3-19.1-15.8-22.6L343.7 36.1C336.1 33.4 328.1 32 320 32zM128 408c0 35.3 86 72 192 72s192-36.7 192-72L496.7 262.6 354.5 314c-11.1 4-22.8 6-34.5 6s-23.5-2-34.5-6L143.3 262.6 128 408z"></path>
                <path className="fa-secondary" d="M325.6 139.8c8.3-3.1 12.5-12.3 9.4-20.6s-12.3-12.5-20.6-9.4L154.9 169.6C92.4 193.1 50.4 251.8 48.1 318.2c-.1 .6-.1 1.2-.1 1.8c0 28.4-10.8 57.8-22.3 80.8c-6.5 13-13.9 25.8-22.5 37.6C0 442.7-.9 448.3 .9 453.4s6 8.9 11.2 10.2l64 16c4.2 1.1 8.7 .3 12.4-2s6.3-6.1 7.1-10.4c8.6-42.8 4.3-81.2-2.1-108.7c-3.2-14-7.5-28.3-13.4-41.5c2.8-52.5 36.4-98.7 86-117.3l159.4-59.8z"></path>
              </svg>
            </div>
            <h3 className="h3">Education</h3>
          </div>
          <ol className="timeline-list">
            <li className="timeline-item">
              <h4 className="h4 timeline-item-title">Azizul Haque College</h4>
              <span>2017 — 2020</span>
              <p className="timeline-text"> I commenced my Philosophy Honours program at Azizul Haque College in 2017. While I did not complete the program. </p>
            </li>
            <li className="timeline-item">
              <h4 className="h4 timeline-item-title">Altadighi Fazil Madrasah</h4>
              <span>2008 — 2015</span>
              <p className="timeline-text"> During my time at Altadighi Fazil Madrasah from 2008 to 2015, I completed both my Dakhil and Alim education. This period was instrumental in expanding my knowledge base and nurturing my academic growth. </p>
            </li>
            <li className="timeline-item">
              <h4 className="h4 timeline-item-title">Darugram Swatantra Ebtedayi Madrasah</h4>
              <span>2003 — 2007</span>
              <p className="timeline-text">During my primary education at Darugram Swatantra Ebtedayi Madrasah from 2003 to 2007, I received a strong foundation in core subjects. </p>
            </li>
          </ol>
        </section>
        <section className="timeline">
          <div className="title-wrapper">
            <div className="icon-box">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                <path className="fa-primary" d="M352 448H544V304H352V448zm-48 0V288c0-17.7 14.3-32 32-32H560c17.7 0 32 14.3 32 32V448h32c8.8 0 16 7.2 16 16c0 26.5-21.5 48-48 48H544 352 304c-26.5 0-48-21.5-48-48c0-8.8 7.2-16 16-16h32z"></path>
                <path className="fa-secondary" d="M261.7 8.5c-12.3-11.3-31.2-11.3-43.4 0l-208 192c-9.7 8.9-12.9 22.9-8.1 35.2S18.8 256 32 256H64V368c0 26.5 21.5 48 48 48H272V288H208c-8.8 0-16-7.2-16-16V208c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16v37.7c11.7-13.3 28.9-21.7 48-21.7H480c0-8.8-3.6-17.3-10.3-23.5l-208-192z"></path>
              </svg>
            </div>
            <h3 className="h3">Experience</h3>
          </div>
          <ol className="timeline-list">
            <li className="timeline-item">
              <h4 className="h4 timeline-item-title">Junior Web Developer</h4>
              <span>2022 — Present</span>
              <p className="timeline-text"> In my role as a Junior Web Developer at MV since 2022, I have been actively involved in web development projects, contributing to the design and implementation of web applications and websites. My responsibilities include coding, troubleshooting, and collaborating with cross-functional teams to deliver high-quality web solutions. </p>
            </li>
            <li className="timeline-item">
              <h4 className="h4 timeline-item-title">Web Developer</h4>
              <span>2018 — 2022</span>
              <p className="timeline-text"> During my tenure as a Web Developer at Source Of Product from 2019 to 2022, I played a pivotal role in creating and maintaining web applications. I was responsible for front-end and back-end development, ensuring the functionality and user experience of the platforms. My contributions significantly contributed to the company's online presence and user engagement. </p>
            </li>
            <li className="timeline-item">
              <h4 className="h4 timeline-item-title">User Support Manager</h4>
              <span>2015 — 2016</span>
              <p className="timeline-text"> As the User Support Manager at Tontri Technologies from 2015 to 2016, I led a team responsible for providing exceptional customer support. I ensured that user inquiries and issues were addressed promptly and effectively, improving customer satisfaction and retention. My role required strong interpersonal and problem-solving skills, contributing to a positive user experience. </p>
            </li>
          </ol>
        </section>
        <section className="skill">
          <h3 className="h3 skills-title">My skills</h3>
          <ul className="skills-list content-card">
            <li className="skills-item">
              <div className="title-wrapper">
                <h5 className="h5">PHP</h5>
                <data value="70">70%</data>
              </div>
              <div className="skill-progress-bg">
                <div className="skill-progress-fill" style={{ width: '70%' }}></div>
              </div>
            </li>
            <li className="skills-item">
              <div className="title-wrapper">
                <h5 className="h5">CSS</h5>
                <data value="90">90%</data>
              </div>
              <div className="skill-progress-bg">
                <div className="skill-progress-fill" style={{ width: '90%' }}></div>
              </div>
            </li>
            <li className="skills-item">
              <div className="title-wrapper">
                <h5 className="h5">HTML</h5>
                <data value="90">90%</data>
              </div>
              <div className="skill-progress-bg">
                <div className="skill-progress-fill" style={{ width: '90%' }}></div>
              </div>
            </li>
            <li className="skills-item">
              <div className="title-wrapper">
                <h5 className="h5">JS</h5>
                <data value="80">80%</data>
              </div>
              <div className="skill-progress-bg">
                <div className="skill-progress-fill" style={{ width: '80%' }}></div>
              </div>
            </li>
          </ul>
        </section>
    </Layout>
  )
}
export default ResumePage
export const pageQuery = graphql`
  query ResumePageQuery{
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
